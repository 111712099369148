import { useEffect, useState } from "react";
import "./DiscoverActionCards.scss";

const DiscoverActionCards = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.addEventListener("load", handleIsDesktop);
    window.addEventListener("resize", handleIsDesktop);

    handleIsDesktop();
    handleStickyImages();
  });

  const handleStickyImages = () => {
    if (typeof window.IntersectionObserver !== "undefined") {
      let options = {
        threshold: [0.5, 1],
      };

      const targetContent = document.querySelectorAll(".content");

      function handleIntersection(entries) {
        entries.forEach((entry) => {
          const image = document.querySelector(
            ".img-" + entry.target.dataset.id
          );

          if (image) {
            if (entry.isIntersecting) {
              document
                .querySelector(".img-" + entry.target.dataset.id)
                .classList.add("active");
            } else {
              document
                .querySelector(".img-" + entry.target.dataset.id)
                .classList.remove("active");
            }
          }
        });
      }

      const observer = new IntersectionObserver(handleIntersection, options);
      targetContent.forEach((target) => observer.observe(target));
    }
  };

  const handleIsDesktop = () => {
    if (window.innerWidth > 1024) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };

  const data = [
    {
      type: "Combat Global Warming Card",
      heading: "The Ultimate Goal",
      desc: "Players combat global warming by addressing various environmental challenges, such as air, water, and soil pollution, habitat loss, biodiversity loss, and more. The player with the highest points wins, earning the prestigious and distinguished Combat Global Warming card.",
      image: "globalwarming-c.png",
    },
    {
      type: "Tackle Cards",
      heading: "Environmental Crises",
      desc: "The nine intricately designed Tackle cards each tell a powerful story about a specific environmental challenge. Players strategically form and Perfect Tackle sets to symbolize their commitment to overcoming these issues. Each Tackle card enriches gameplay and visually highlights the importance of collective action for our planet.",
      image: "tackle-c-02.png",
    },
    {
      type: "Element Cards",
      heading: "Facilitators of Environmental Harmony",
      desc: "Element cards are the powerful catalysts, standing as beacons of hope and solutions in the battle against adversity. The nine Element cards, each meticulously crafted, symbolize a key element that contributes to mitigating the environmental challenges depicted by the corresponding Tackle card.",
      image: "element-c.png",
    },
    {
      type: "Multipurpose Element Cards",
      heading: "Versatile Allies in Environmental Solutions",
      desc: "The nine artfully designed Multipurpose Element cards, each embody an element or factor that plays a pivotal role in addressing three distinct environmental challenges. Players can wield these versatile allies to enhance gameplay.",
      image: "multipurpose-c.png",
    },
    {
      type: "Havoc Cards",
      heading: "Disruptive Force in Combating Environmental Challenges",
      desc: "Havoc cards are the agents of chaos, capable of disrupting even the most ingenious plans to Perfect Tackle sets. With the power to thwart environmental goals, these cards embody unpredictability and pose a formidable challenge to players aspiring for eco supremacy. The chaos it introduces reflects the real-world complexities of environmental issues.",
      image: "havoc-c.png",
    },
    {
      type: "Action Cards",
      heading: "Tactical Game-Changers",
      desc: "Action cards introduce dynamic elements to gameplay, allowing players to tactically influence the outcome of environmental challenges. Among these, powerful cards like Wild, Lock, Key, and Force cards add depth and unpredictability, offering players opportunities to control, disrupt, or defend against various in-game situations.",
      image: "action-c.png",
    },
  ];

  return (
    <div className="DiscoverActionCards">
      {isDesktop ? (
        <div className="wrapper desktop-v">
          <div className="half">
            <div className="sticky-images">
              {data.map((item, i) => {
                return (
                  <img
                    className={"img-" + (i + 1)}
                    src={"/assets/images/" + item.image}
                    alt=""
                    key={i}
                  />
                );
              })}
            </div>
          </div>
          <div className="half">
            {data.map((item, i) => {
              return (
                <div className="content" data-id={i + 1} key={i}>
                  <div className="heading">
                    <p className="sub-text">{item.type}</p>
                    <h2>{item.heading}</h2>
                  </div>
                  <div className="separator">
                    <img src="/assets/images/separator.svg" alt="" />
                  </div>
                  <p>{item.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="wrapper mobile-v">
          {data.map((item, i) => {
            return (
              <div className="row" key={i}>
                <div className="half">
                  <img src={"/assets/images/" + item.image} alt="" />
                </div>
                <div className="half">
                  <div className="content">
                    <div className="heading">
                      <p className="sub-text">{item.type}</p>
                      <h2>{item.heading}</h2>
                    </div>
                    <div className="separator">
                      <img src="/assets/images/separator.svg" alt="" />
                    </div>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DiscoverActionCards;
