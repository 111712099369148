import { Fade } from "react-awesome-reveal";

import "./Home.scss";

import Hero from "../../components/Hero/Hero";
import DiscoverActionCards from "../../components/DiscoverActionCards/DiscoverActionCards";

const Home = () => {
  return (
    <div className="Home">
      <Hero parallaxSpeedMultiplier={1} />
      <div className="Intro">
        <div className="wrapper">
          <div className="top">
            <Fade direction="down">
              <h2>
                Be Prepared To Have Your Mind Blown With The Coolest New Game:
                1&deg; Hotter
              </h2>
            </Fade>
            <Fade direction="down">
              <p>
                Forget casual fun, this isn't your average game. 1&deg; Hotter
                is a heart-pounding rollercoaster for your mind, where strategic
                twists and daring leaps leave you breathless with anticipation.
              </p>
            </Fade>
          </div>
          <div className="middle">
            <Fade direction="down">
              <h3>Warning &ndash; Side effects may include the following:</h3>
              <ul>
                <li>Extreme fun.</li>
                <li>Bursts of brilliance.</li>
                <li>
                  As you chase the thrill of the game, you'll stumble upon
                  something unexpected: knowledge. It'll sneak in during the
                  play, kindling a spark of curiosity that flares into a bonfire
                  of understanding.
                </li>
                <li>
                  An urge to act for personal improvement -- Learning fuels
                  Action.
                </li>
                <li>
                  An ignited passion to tackle real-world challenges -- Action
                  sparks Transformation.
                </li>
              </ul>
            </Fade>
          </div>
          <div className="bottom">
            <Fade direction="down">
              <h1>Play at your own risk, the future's on fire</h1>
            </Fade>
          </div>
        </div>
      </div>
      <div className="message">
        <div className="wrapper">
          <Fade direction="down">
            <h4>
              Are you ready to join the blaze where Purpose and Play converge
              through the fusion of Non-Action and Action cards, shaping{" "}
              <span className="accent-color">
                an unparalleled gaming experience?
              </span>
            </h4>
          </Fade>
        </div>
      </div>
      <DiscoverActionCards />
    </div>
  );
};

export default Home;
