import { useEffect, useRef } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./Hero.scss";

const Hero = ({ parallaxSpeedMultiplier }) => {
  const parallaxImagesDivRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    parallaxImagesDivRef.current.querySelector("img").onload = (e) => {
      const parallaxHeight = e.target.offsetHeight;
      const numberOfLayers = parallaxImagesDivRef.current.children.length;

      let ctx = gsap.context(() => {
        gsap.to(".parallax-images .frame", {
          scrollTrigger: {
            scrub: true,
          },
          y: (i, target) => {
            return (
              parallaxHeight *
              (i === numberOfLayers - 1 ? -i : i) *
              target.dataset.speed
            ); // last layer should go upward, hence the "-i";
          },
          ease: "none",
        });
      });

      return () => ctx.revert();
    };
  });

  return (
    <div className="Hero">
      <div className="parallax img-div">
        <div ref={parallaxImagesDivRef} className="parallax-images">
          <img
            className="frame"
            src="/assets/images/parallax/bg.png"
            data-speed={1 * parallaxSpeedMultiplier}
            alt=""
          />
          {/* <div className="layer frame">
            <p>A Simply Extraordinary Game &ndash; The Heat's Ours to Tame!</p>
          </div> */}
          <img
            className="frame"
            src="/assets/images/parallax/earth.png"
            data-speed={4 * parallaxSpeedMultiplier}
            alt=""
          />
          <img
            className="toAndFro bombs frame"
            src="/assets/images/parallax/bombs.png"
            data-speed={1 * parallaxSpeedMultiplier}
            alt=""
          />
          <img
            className="toAndFro fire frame"
            src="/assets/images/parallax/fireballs.png"
            data-speed={1 * parallaxSpeedMultiplier}
            alt=""
          />
          <img
            className="frame"
            src="/assets/images/parallax/heat.png"
            data-speed={0.2 * parallaxSpeedMultiplier}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  parallaxSpeedMultiplier: 1,
};

export default Hero;
