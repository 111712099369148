import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <footer id="footer" className="Footer">
      <div className="logo">
        <Link to="/">
          <img src="/assets/images/1-hotter-logo.svg" alt="" />
        </Link>
      </div>
      <div id="contact" className="inquiry-text">
        <p>
          For inquiries, support, or feedback, please email us at{" "}
          <a href="mailto:support@1degreehotter.com">
            support@1degreehotter.com
          </a>
          .<br />
          We aim to respond within 48 hours. Thank you for reaching out!
        </p>
      </div>
      { pathname === "/" ?
      <div id="disclaimer" className="disclaimer-text">
        <h3>Disclaimer</h3>
        <p>
        This website is dedicated to promoting awareness about environmental issues and solutions through a card game. The information provided on the cards is based on environmental facts obtained from reliable sources. However, we do not guarantee the accuracy, completeness, or timeliness of the information. Users are encouraged to verify details independently and consult authoritative sources for specific environmental data. The inclusion of facts and graphics is for educational and entertainment purposes only. The creators and operators of this website are not liable for any errors, omissions, or consequences arising from the use of information presented herein. Additionally, the environmental facts are subject to change, and we reserve the right to update content without notice. Any links to third-party websites are provided for your convenience and do not constitute an endorsement of their content. 1° Hotter is not responsible for the content or privacy practices of external sites. By using this website, you acknowledge and agree to these terms.
        </p>
      </div> : null
      }
      <div className="copyright-text">
        <p>&copy; 2023 1&deg; Hotter. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
