import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./Header.scss";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Header = () => {
  const location = useLocation();

  const [stickyHeader, setStickyHeader] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);


  const handleClick = () => {
    scrollToTop();
    setIsMobileNavActive(false);}

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > window.innerHeight
        ? setStickyHeader(true)
        : setStickyHeader(false);
    });

    window.addEventListener("resize", () => {
      mobileMenuHandle();
    });

    window.addEventListener("load", () => {
      mobileMenuHandle();
    });
  });

  const mobileMenuHandle = () => {
    window.innerWidth < 600 ? setMobileMenu(true) : setMobileMenu(false);
  };

  return (
    <header className={stickyHeader ? "Header sticky" : "Header"}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/"
          className={location.pathname === "/" ? "active" : ""}
          onClick={handleClick}>
            <img src="/assets/images/1-hotter-logo.svg" alt="" />
          </Link>
          <div className="tagline">
            <p>A Simply Extraordinary Game &ndash; The Heat's Ours to Tame!</p>
          </div>
        </div>
        <nav className={isMobileNavActive ? "active" : ""}>
          <Link
            to="/"
            className={location.pathname === "/" ? "active" : ""}
            onClick={handleClick}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={location.pathname === "/about" ? "active" : ""}
            onClick={handleClick}
          >
            About
          </Link>
          <a
            href="/assets/instructions.pdf"
            target="_blank"
            onClick={() => setIsMobileNavActive(false)}
          >
            Instructions
          </a>
          <a href="#footer" onClick={() => setIsMobileNavActive(false)}>
            Contact Us
          </a>
        </nav>
        {mobileMenu ? (
          <div
            className="mobile-menu"
            onClick={() => setIsMobileNavActive(!isMobileNavActive)}
          >
            {isMobileNavActive ? (
              <i className="ri-close-fill"></i>
            ) : (
              <i className="ri-menu-fill"></i>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default Header;
