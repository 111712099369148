import "./About.scss";

import Hero from "../../components/Hero/Hero";
import GameIdeas from "../../components/GameIdeas/GameIdeas";

const About = () => {
  return (
    <div className="About">
      <Hero parallaxSpeedMultiplier={0.4} />

      <div className="Intro">
        <div className="wrapper">
          <div className="top">
            <h2>A VISION REALIZED: FROM THE MIND BEHIND 1&deg; HOTTER</h2>
            <div className="content">
              <p>
                Welcome to 1&deg; Hotter, a revolutionary card game where
                endless fun, excitement, and learning harmonize to create an
                exceptional experience.
              </p>
              <p>
                Since 1880, Earth's average temperature has risen by at least
                1.1&deg;C (NASA data). With each degree, we edge closer to a
                colossal climate catastrophe. 1&deg; Hotter is the cool,
                pioneering game in this sizzling world, with remarkable
                potential to ignite transformation during your pursuit for
                victory.
              </p>
            </div>
          </div>
          <div className="bottom">
            <h4>Together, let's change the world!</h4>
            <p>
              Sustainably yours,
              <br />A<span className="superValue">3</span>K
              <br />
              <span className="highlighted-txt">
                Founder/Game Creator/Card Designer/Managing Leader
              </span>
              <br />
              A High School Student (Graduating Class of 2024)
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="message">
        <div className="wrapper">
          <h3>WINNING AND STRATEGY</h3>
          <p>
            1&deg; Hotter offers an exciting blend of strategy and
            decision-making, where players Complete and Perfect Tackle sets,
            capture opponents' cards, and skilfully utilize Element and
            Multipurpose Element cards, Havoc cards, and Action cards. The key
            objective for each player is to secure victory by Completing and
            Perfecting Tackle Sets. Throughout this process, the gameplay is
            enriched with opportunities to capture other players' Tackle cards
            or sets, implement strategic Locks, or disrupt their progress with
            Havoc cards. Players can further enhance their strategies by
            utilizing a variety of Action cards, including "Remorse," "Trade,"
            and "Wild." This dynamic interplay of tactics and decision-making
            adds depth and excitement to the gaming experience.
          </p>
        </div>
      </div>

      <GameIdeas />
    </div>
  );
};

export default About;
