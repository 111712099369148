import { useEffect, useRef, useState } from "react";

import "./GameIdeas.scss";
import "./GameIdeas.css";

const GameIdeas = () => {
  const [clicked, setClicked] = useState(0);
  const [animateCards, setAnimateCards] = useState(false);
  const cardsRef = useRef(null);

  const toggle = (index) => {
    // if (clicked === index) {
    //   return setClicked(null);
    // }
    setClicked(index);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (hasEnteredViewport(cardsRef.current) && window.innerWidth > 1024) {
        setAnimateCards(true);
      } else {
        setAnimateCards(false);
      }
    });
  });

  const hasEnteredViewport = (el) => {
    var rect = el ? el.getBoundingClientRect() : "";

    // checking for partial visibility
    if (rect.top < window.innerHeight && rect.bottom >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const accordionData = [
    {
      heading: "Endless Fun and Strategic Depth",
      details:
        "Discover the perfect fusion of limitless fun and strategic brilliance, transforming every moment into a captivating and intellectually satisfying journey.",
    },
    {
      heading: "Exquisite Design",
      details:
        "Experience the intricate and meaningful designs of each Non-Action card, transcending mere visual appeal, triggering curiosity, and leaving a lasting impression.",
    },
    {
      heading: "Educational Significance",
      details:
        "Delve into the different environmental issues, their consequences, and solutions. Learning is fun and feels like a victory lap, not a chore.",
    },
    {
      heading: "Inspiration for Change",
      details: (
        <span>
          Immerse in the visual and symbolic representations that make knowledge
          tangible, relatable, and enduring, leaving an inspirational impact on
          both the conscious and subconscious levels of your mind.
        </span>
      ),
    },
  ];

  return (
    <div className="GameIdeas">
      <div className="wrapper">
        <div className="half">
          <div className="heading">
            <p className="sub-text">1&deg; HOTTER'S</p>
            <h2>GAME CHANGING FEATURES</h2>
          </div>
          <div className="accordion-main">
            {accordionData.map((item, i) => {
              return (
                <div
                  className={
                    clicked === i
                      ? "accordion-container active"
                      : "accordion-container"
                  }
                  key={i}
                >
                  <div className="accordion" onClick={() => toggle(i)}>
                    <h3>{item.heading}</h3>
                  </div>
                  {clicked === i ? <p>{item.details}</p> : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="half">
          <div
            ref={cardsRef}
            className={animateCards ? "cards animate" : "cards"}
          >
            {window.innerWidth > 1024 ? (
              <>
                <img src="/assets/images/1-hotter-card-01.png" alt="" />
                <img src="/assets/images/1-hotter-card-02.png" alt="" />
              </>
            ) : (
             <div className="hotterDiv">
              <img className="hotterImg1"  src="/assets/images/1-hotter-card-01.png" alt="" />
              <img className="hotterImg"  src="/assets/images/1-hotter-card-02.png" alt="" />
             </div>
            )}
          </div>
        </div>
      </div>
      <div className="wrapper bottom">
        <p>
          Beyond these thrilling features, what shines through this exhilarating
          experience is 1&deg; Hotter's unique and novel concept where Players
          aren't merely competing for victory; they participate in a shared
          journey toward environmental awareness and positive impact.
        </p>
      </div>
    </div>
  );
};

export default GameIdeas;
